import { compose } from 'redux';

import {
  AUTH_HEADER_NAME, CITI_PROXY_API_ENDPOINT,
  POLICY_API_ENDPOINT, TURNSTILE_API_ENDPOINT,
  TURNSTILE_AUTH_HEADER_NAME, CARBON_API_ENDPOINT,
} from 'services/constants';

import AxiosClient from 'services/utils/AxiosClient';
import HeaderAuth from 'services/utils/HeaderAuth';
import withAuth from 'services/utils/withAuth';
import withMock from 'services/utils/withMock';

const headerAuth = new HeaderAuth(AUTH_HEADER_NAME, 'Bearer');
const turnstileHeaderAuth = new HeaderAuth(TURNSTILE_AUTH_HEADER_NAME, '');
const AuthClient = process.env.NODE_ENV !== 'production' && process.env.REACT_APP_MOCK === 'on'
  ? compose(withMock, withAuth(headerAuth))(AxiosClient)
  : withAuth(headerAuth)(AxiosClient);
const TurnstileAuthClient = process.env.NODE_ENV !== 'production' && process.env.REACT_APP_MOCK === 'on'
  ? compose(withMock, withAuth(turnstileHeaderAuth))(AxiosClient)
  : withAuth(turnstileHeaderAuth)(AxiosClient);

export const citiClient = new AuthClient({
  baseURL: CITI_PROXY_API_ENDPOINT,
});
export const policyClient = new AuthClient({
  baseURL: POLICY_API_ENDPOINT,
});
export const turnstileClient = new TurnstileAuthClient({
  baseURL: TURNSTILE_API_ENDPOINT,
});
export const carbonClient = new AuthClient({
  baseURL: CARBON_API_ENDPOINT,
});

export function authorize(token) {
  citiClient.authorize(token);
  policyClient.authorize(token);
  turnstileClient.authorize(token);
  carbonClient.authorize(token);
}
