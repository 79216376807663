import React from 'react';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import { Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LocaleProvider } from 'antd';
import locale from 'antd/lib/locale-provider/zh_CN';

import {
  APP_NAME,
  APP_VERSION,
  APP_DESCRIPTION,
  APP_SHA,
  WEBSITE_TITLE_TEMPLATE,
} from 'services/constants';
import store from 'services/store';
import history from 'services/history';
import Lodaing from 'components/Loading';
import RouterQuery from 'components/RouterQuery';

const Main = Loadable({
  loader: () => import('scenes/Main'),
  loading: Lodaing,
});
const Forbidden = Loadable({
  loader: () => import('scenes/Forbidden'),
  loading: Lodaing,
});

function App() {
  return (
    <LocaleProvider locale={locale}>
      <Provider store={store}>
        <Router history={history}>
          <RouterQuery>
            <>
              <Helmet titleTemplate={WEBSITE_TITLE_TEMPLATE}>
                <meta
                  name="app-meta"
                  content={`name=${APP_NAME},version=${APP_VERSION},description=${APP_DESCRIPTION},sha=${APP_SHA}`}
                />
              </Helmet>
              <Switch>
                <Route
                  path="/403"
                  component={Forbidden}
                />
                <Route
                  path="/"
                  component={Main}
                />
              </Switch>
            </>
          </RouterQuery>
        </Router>
      </Provider>
    </LocaleProvider>
  );
}

export default App;
