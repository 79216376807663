import { useContext } from 'react';
import { __RouterContext as RouterContext } from 'react-router-dom';

function useRouter() {
  const router = useContext(RouterContext);

  return router;
}

export default useRouter;
