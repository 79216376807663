import pathToRegExp from 'path-to-regexp';

function createMatchPath(path, options) {
  const keys = [];
  const reg = pathToRegExp(path, keys, options);

  return (url) => {
    const result = reg.exec(url);

    if (!result) {
      return null;
    }

    const params = keys.reduce((result, key, index) => {
      const {
        name,
      } = key;
      const {
        [index + 1]: value,
      } = result;

      // eslint-disable-next-line no-param-reassign
      result[name] = value;

      return result;
    }, {});

    return {
      url,
      path,
      params,
    };
  };
}

function withMock(Client) {
  class MockClient extends Client {
    _routes = {}

    route(options) {
      const {
        path,
        method,
        handle,
        ...matchOptions
      } = options;

      let methods;
      let matchPath;

      if (method === undefined) {
        methods = [];
      } else if (Array.isArray(method)) {
        methods = method;
      } else {
        methods = [method];
      }

      if (methods.length === 0) {
        if (this._routes[path] === undefined) {
          matchPath = createMatchPath(path, matchOptions);

          this._routes[path] = {
            matchPath,
            handle,
            methods: {},
          };
        } else {
          this._routes[path].handle = handle;
        }
      } else {
        methods.forEach((method) => {
          const methodKey = method.toUpperCase();

          if (this._routes[path] === undefined) {
            if (!matchPath) {
              matchPath = createMatchPath(path, matchOptions);
            }

            this._routes[path] = {
              matchPath,
              methods: {
                [methodKey]: handle,
              },
            };
          } else {
            this._routes[path].methods[methodKey] = handle;
          }
        });
      }

      return this;
    }

    all(path, handle, matchOptions) {
      return this.route({
        ...matchOptions,
        path,
        handle,
      });
    }

    get(path, handle, matchOptions) {
      return this.route({
        ...matchOptions,
        path,
        handle,
        method: 'get',
      });
    }

    post(path, handle, matchOptions) {
      return this.route({
        ...matchOptions,
        path,
        handle,
        method: 'post',
      });
    }

    delete(path, handle, matchOptions) {
      return this.route({
        ...matchOptions,
        path,
        handle,
        method: 'delete',
      });
    }

    put(path, handle, matchOptions) {
      return this.route({
        ...matchOptions,
        path,
        handle,
        method: 'put',
      });
    }

    patch(path, handle, matchOptions) {
      return this.route({
        ...matchOptions,
        path,
        handle,
        method: 'patch',
      });
    }

    head(path, handle, matchOptions) {
      return this.route({
        ...matchOptions,
        path,
        handle,
        method: 'head',
      });
    }

    options(path, handle, matchOptions) {
      return this.route({
        ...matchOptions,
        path,
        handle,
        method: 'options',
      });
    }

    handle(config) {
      const {
        url,
        method,
      } = config;

      const routes = Object.values(this._routes);
      const {
        length,
      } = routes;

      for (let i = 0; i < length; i += 1) {
        const {
          [i]: {
            matchPath,
            handle: allHandle,
            methods,
          },
        } = routes;

        const match = matchPath(url);
        const methodKey = method.toUpperCase();

        if (match) {
          const {
            [methodKey]: handle = allHandle,
          } = methods;

          if (handle) {
            const start = Date.now();

            const request = {
              ...config,
              match,
            };

            return new Promise((resolve) => {
              resolve(handle(request));
            })
              .then((response) => {
                const end = Date.now();

                /* eslint-disable no-console */
                console.group(`Mock "${methodKey} ${url}" in ${end - start}ms`);
                console.info(' Request:', request);
                console.info('Response:', response);
                console.groupEnd();
                /* eslint-enable no-console */

                return response;
              });
          }
        }
      }

      return false;
    }

    request(config) {
      return this.handle(config) || super.request(config);
    }
  }

  return MockClient;
}

export default withMock;
