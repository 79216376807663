import React from 'react';
import PropTypes from 'prop-types';

function Render(props) {
  const {
    component,
    as: Component = component,
    render,
    children,
    ...ownProps
  } = props;

  if (Component) {
    return (
      <Component {...ownProps}>
        {children}
      </Component>
    );
  }

  if (render) {
    return render(ownProps);
  }

  if (typeof children === 'function') {
    return children(ownProps);
  }

  if (children === undefined) {
    return null;
  }

  return children;
}

if (process.env.NODE_ENV !== 'production') {
  Render.propTypes = {
    component: PropTypes.elementType,
    as: PropTypes.elementType,
    render: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  };
}

export default Render;
