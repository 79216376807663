import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import refine from 'services/utils/refine';
import isExternalPath from 'services/utils/isExternalPath';

function AppLink(props) {
  const {
    to: toProp,
    replace,
    children,
    ...otherProps
  } = props;

  const to = refine(toProp, props);
  const isAction = to === undefined;
  const isExternal = typeof to === 'string' && isExternalPath(to);

  if (isAction || isExternal) {
    return (
      <a
        {...otherProps}
        rel="noopener noreferrer"
        href={to}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      {...otherProps}
      replace={replace}
      to={to}
    >
      {children}
    </Link>
  );
}

if (process.env.NODE_ENV !== 'production') {
  AppLink.propTypes = {
    replace: PropTypes.bool,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
    children: PropTypes.node,
  };
}

export default AppLink;
