import axios from 'axios';

export function createAxiosInstance(config, interceptors) {
  const instance = axios.create(config);

  if (interceptors) {
    const {
      request,
      response,
    } = interceptors;

    if (request) {
      instance.interceptors.request.use(...request);
    }

    if (response) {
      instance.interceptors.response.use(...response);
    }
  }

  return instance;
}

class AxiosClient {
  constructor(config, interceptors) {
    this._config = config;
    this._interceptors = interceptors;
    this._instance = null;
  }

  buildInstance() {
    const instance = createAxiosInstance(this._config, this._interceptors);

    this._instance = instance;

    return this;
  }

  config(config) {
    if (config === undefined) {
      return this._config;
    }

    this._config = config;

    if (this._instance) {
      this.buildInstance();
    }

    return this;
  }

  interceptors(interceptors) {
    if (interceptors === undefined) {
      return this._interceptors;
    }

    this._interceptors = interceptors;

    if (this._instance) {
      this.buildInstance();
    }

    return this;
  }

  patchConfig(config) {
    return this.config({
      ...this._config,
      ...config,
    });
  }

  patchInterceptors(interceptors) {
    return this.interceptors({
      ...this._interceptors,
      ...interceptors,
    });
  }

  getInstance() {
    if (this._instance === null) {
      this.buildInstance();
    }

    return this._instance;
  }

  request(config) {
    return this
      .getInstance()
      .request(config);
  }
}

export default AxiosClient;
