import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';

import useRouter from 'hooks/useRouter';
import Render from 'components/Render';

export const RouterQueryContext = createContext(null);

const {
  Provider,
} = RouterQueryContext;

export function indexFormatParse(str) {
  return parse(str, {
    arrayFormat: 'index',
  });
}

export function indexFormatStringify(obj) {
  return stringify(obj, {
    arrayFormat: 'index',
  });
}

export function useRouterQuery(options = {}) {
  const {
    parse: parseOption,
    stringify: stringifyOption,
  } = options;
  const router = useRouter();
  const parentQueryContextValue = useContext(RouterQueryContext);
  const queryContextValue = useMemo(() => {
    const parse = parseOption || indexFormatParse;
    const stringify = stringifyOption || indexFormatStringify;

    function createLocation(location) {
      if (typeof location === 'object' && location !== null) {
        const {
          query,
          search,
        } = location;

        const nextSearch = query ? stringify({
          ...parse(search),
          ...query,
        }) : search;

        return {
          ...location,
          search: nextSearch,
        };
      }

      return location;
    }

    if (!parentQueryContextValue || parseOption || stringifyOption) {
      const {
        location: {
          search,
        },
        history: historyRouterProp,
        match: matchRouterProp,
      } = router;
      const query = parse(search);
      const history = {
        ...historyRouterProp,
        createLocation,
      };
      const match = {
        ...matchRouterProp,
        query,
      };

      return {
        ...router,
        history,
        match,
      };
    }

    return parentQueryContextValue;
  }, [parseOption, stringifyOption, router, parentQueryContextValue]);

  return queryContextValue;
}

function RouterQuery(props) {
  const {
    parse,
    stringify,
    ...otherProps
  } = props;

  const value = useRouterQuery({
    parse,
    stringify,
  });

  return (
    <Provider value={value}>
      {Render({
        ...otherProps,
        ...value,
      })}
    </Provider>
  );
}

if (process.env.NODE_ENV !== 'production') {
  RouterQuery.propTypes = {
    parse: PropTypes.func,
    stringify: PropTypes.func,
  };
}

export default RouterQuery;
